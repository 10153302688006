import { Form } from "react-bootstrap";

const Field = ({
  name,
  label,
  register,
  formState,
  validate,
  required,
  ...props
}: any) => {
  return (
    <>
      <Form.Group className="form-group">
        <Form.Label htmlFor={name}>{label}</Form.Label>
        <Form.Control
          id={name}
          isInvalid={!!formState.errors[name]}
          {...register(name, { required, validate })}
          {...props}
        />
        <Form.Control.Feedback type="invalid">
          {formState.errors?.[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default Field;
